import axios from 'axios';

const config = {
  back_url: "https://po1uecq0ij.execute-api.eu-west-1.amazonaws.com/dev",
}

const LOGIN_URL = config.back_url + '/auth/login';
const LOGIN_GOOGLE_URL = config.back_url + '/auth/loginGoogle';
const LOGOUT_URL = config.back_url + '/auth/logout';
const COMPANIES_URL = config.back_url + '/auth/companies';
const PARTNER_COMPANIES_URL =
  config.back_url + '/auth/companies/partnerCustomers';
const PARTNERS_URL = config.back_url + '/auth/partners';
const CUSTOMERS_URL = config.back_url + '/auth/customers';
const UPDATE_PASSWORD_URL = config.back_url + '/auth/users/password';
const REMOVE_ONE_USER = config.back_url + '/auth/users/';

const authentication = {
  login: async (data) => {
    return await axios.post(LOGIN_URL, data);
  },
  loginGoogle: async (data) => {
    return await axios.post(LOGIN_GOOGLE_URL, data);
  },
  logout: async () => {
    return await axios.post(LOGOUT_URL, {});
  },
  getCompanies: async () => {
    return await axios.get(COMPANIES_URL, {});
  },
  getCompaniesRemovalPermissions: async () => {
    return await axios.get(`${COMPANIES_URL}/removalPermissions`);
  },
  removeOneCompany: async (companyId) => {
    return await axios.delete(`${COMPANIES_URL}/${companyId}`);
  },
  updateOneCompany: async (companyId, data) => {
    return await axios.put(`${COMPANIES_URL}/${companyId}`, data);
  },
  createCompany: async (data) => {
    return await axios.post(`${COMPANIES_URL}/create`, data);
  },
  getPartnerCustomers: async (partnerCompanyId = null, includeSelf = false) => {
    let queryString = '';
    if (+partnerCompanyId) {
      queryString += `?companyId=${partnerCompanyId}`;
      if (includeSelf) {
        queryString += `&includeSelf=${Number(includeSelf)}`;
      }
    } else if (includeSelf) {
      queryString += `?includeSelf=${Number(includeSelf)}`;
    }
    return await axios.get(PARTNER_COMPANIES_URL + queryString, {});
  },
  getPartners: async () => {
    return await axios.get(PARTNERS_URL, {});
  },
  getCustomers: async () => {
    return await axios.get(CUSTOMERS_URL, {});
  },
  updateUserPassword: async (data) => {
    return await axios.put(UPDATE_PASSWORD_URL, data);
  },
  removeOneUser: async (userId) => {
    return await axios.delete(REMOVE_ONE_USER + userId, {});
  },
};

export default authentication;
