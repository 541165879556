import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import resultsApi from 'Api/results';

import { ROUTE_HEATMAPS } from 'Config/routes';

import { setHeatmapsSuccess } from 'Features/Heatmaps/heatmapsSlice';
import {
  setCurrentTestDate,
  setCurrentTestName,
  testsSelector,
} from 'Features/Tests/testsSlice';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const TestsItem = function TestsItem({
  buildingId,
  name,
  surveyDate,
  testId,
  allReviewed,
  atLeastOneReviewed,
  afterUpdate,
  displayNumber,
  isDraggable,
  dragHandleProps = {},
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { currentTestId } = useSelector(testsSelector);

  const handleClick = (event) => {
    dispatch(setCurrentTestName(name));
    dispatch(setCurrentTestDate(surveyDate));
    if (parseInt(currentTestId) !== parseInt(testId)) {
      dispatch(setHeatmapsSuccess({ heatmaps: [], heatmapDisplayNumbers: {} }));
    }
    const path = generatePath(ROUTE_HEATMAPS, {
      buildingId,
      testId,
    });

    if (event.metaKey || event.ctrlKey) {
      window.open(path);
      return;
    }

    navigate(path);
  };

  const handleReviewedUpdate = async (reviewed) => {
    setIsLoading(true);

    try {
      const response = await resultsApi.markAllHeatmapsReviewedForTest(
        testId,
        reviewed,
      );
      if (isValidResponse(response)) {
        afterUpdate();
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        className="testItem-container"
        style={{
          paddingLeft: isDraggable ? '35px' : '16px',
          paddingRight: isDraggable ? '16px' : 0,
        }}
      >
        <div className="testItem-title">
          {isDraggable && (
            <div className="testItem-title--icon" {...dragHandleProps}>
              <DragHandleIcon />
            </div>
          )}
          <Typography
            className={isDraggable ? 'testItem-title--text' : ''}
            gutterBottom
            variant="h6"
            component="div"
            onClick={handleClick}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
          >
            {name}
          </Typography>
        </div>
        <Typography gutterBottom variant="body2" component="div">
          Completed, {surveyDate && `${surveyDate}`}
          {testId ? `, Test ID ${testId}` : ''}
          {displayNumber ? `, Test #${displayNumber}` : ``}
        </Typography>
      </div>
      <div style={{ margin: 'auto 0' }}>
        <Checkbox
          checked={atLeastOneReviewed}
          color={allReviewed ? 'primary' : 'grey'}
          onChange={(e) => handleReviewedUpdate(e.target.checked)}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default React.memo(TestsItem);
