import React from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import TestsListDnD from './TestsListDnD';
import './styles.css';

import DownloadIcon from '@mui/icons-material/Download';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import TestsDraggableList from 'Components/ResultTests/TestsDraggableList';

import { ROUTE_PROJECT_DETAILS } from 'Config/routes';

const ExpandMore = styled((props) => {
  const { ...other } = props;
  delete other.expand;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProjectCard = function ProjectCard({
  buildingId,
  name,
  hasResultsSnapshot,
  regenerateResultsSnapshot,
  tests,
  projectId,
  afterUpdate,
  isDraggable,
  dragHandleProps = {},
  isLoading,
  expanded,
  handleExpandClick,
  handleProjectHeatmapsDownload,
  downloadingHeatmapsProjectId,
}) {
  const navigate = useNavigate();

  const handleProjectTitleClick = (event) => {
    const path = generatePath(ROUTE_PROJECT_DETAILS, {
      projectId,
    });

    if (event.metaKey || event.ctrlKey) {
      window.open(path);
      return;
    }

    navigate(path);
  };

  return (
    <Card className="projectCard">
      <CardContent>
        <Stack direction="row" sx={{ width: '100%' }}>
          {isDraggable && (
            <div className="projectCard-title--icon" {...dragHandleProps}>
              <DragHandleIcon />
            </div>
          )}
          <Typography
            onClick={handleProjectTitleClick}
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
              ml: 1,
            }}
            color="primary"
          >
            {name}
          </Typography>
          {!downloadingHeatmapsProjectId ? (
            <Tooltip title={'Download all heatmaps'} arrow placement="top">
              <span>
                <IconButton
                  color="primary"
                  sx={{ ml: 1, position: 'relative', top: '-3px' }}
                  onClick={() => handleProjectHeatmapsDownload(projectId)}
                >
                  <DownloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <IconButton
              color="primary"
              sx={{ ml: 1, position: 'relative', top: '-3px' }}
              disabled
              className={
                downloadingHeatmapsProjectId === projectId
                  ? 'icon-btn-loading-color'
                  : ``
              }
            >
              <DownloadIcon />
            </IconButton>
          )}
          {!hasResultsSnapshot ? (
            <Tooltip title={'Regenerate results'} arrow placement="top">
              <span>
                <IconButton
                  color="primary"
                  sx={{ ml: 1, position: 'relative', top: '-3px' }}
                  onClick={regenerateResultsSnapshot}
                  disabled={isLoading}
                  className={isLoading ? 'icon-btn-loading-color' : ``}
                >
                  <UpdateIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show list of draggable tests"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Stack>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          Project #{projectId}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto">
        <CardContent>
          <TestsListDnD
            tests={tests}
            buildingId={buildingId}
            afterUpdate={afterUpdate}
            isLoading={isLoading}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default React.memo(ProjectCard);

export const ProjectCardSkeleton = function ProjectCard({ isDraggable }) {
  return (
    <Card className="projectCard">
      <CardContent>
        <Stack direction="row" sx={{ width: '100%' }}>
          {isDraggable && (
            <div
              className="projectCard-title--icon"
              style={{ cursor: 'default' }}
            >
              <DragHandleIcon />
            </div>
          )}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              ml: 1,
            }}
            color="primary"
          >
            <Skeleton width={300} />
          </Typography>
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <ExpandMore
              expand={true}
              aria-expanded={true}
              disabled
              aria-label="Show list of draggable tests"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Stack>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          <Skeleton />
        </Typography>
      </CardContent>
      <Collapse in={true} timeout="auto">
        <CardContent>
          <Box>
            {Array(3)
              .fill(1)
              .map((_, i) => (
                <div
                  key={`project-card-skeleton-tests-draggable-test-skeleton-${
                    _ + i
                  }`}
                  style={{
                    width: 'fit-content',
                    marginBottom: '12px',
                    borderRadius: '6px',
                    padding: '6px 0px',
                    backgroundColor: 'white',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      className="testItem-container"
                      style={{
                        paddingLeft: isDraggable ? '35px' : '16px',
                        paddingRight: isDraggable ? '16px' : 0,
                      }}
                    >
                      <div className="testItem-title">
                        {isDraggable && (
                          <div className="testItem-title--icon">
                            <DragHandleIcon />
                          </div>
                        )}
                        <Typography
                          className={isDraggable ? 'testItem-title--text' : ''}
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <Skeleton width={320} />
                        </Typography>
                      </div>
                      <Typography gutterBottom variant="body2" component="div">
                        <Skeleton width={280} />
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            <TestsDraggableList isLoading={true} tests={[]} buildingId={1} />
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};
