import { PROJECT_STATUSES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import samplePoints from '../../../Api/samplePoints';
import { useFloorplanContext } from '../context';

import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, CircularProgress, TextField } from '@mui/material';

import { isSafetracesAdmin } from 'Config/roles';

const SPItem = ({ sp, reFetch }) => {
  const { setError, testData, projectData } = useFloorplanContext();
  const [SPName, setSPName] = useState(sp.spname);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const canDragSTAdmin =
    !!projectData?.status && projectData?.status !== PROJECT_STATUSES.PUBLISHED;
  const canDragAnyUser =
    typeof testData?.testexecuted === 'boolean' && !testData.testexecuted;

  const [, drag] = useDrag(
    {
      type: 'SP',
      item: {
        ...sp,
        type: 'SP',
        id: sp.spid,
      },
      canDrag: isSafetracesAdmin() ? canDragSTAdmin : canDragAnyUser,
    },
    [sp, SPName, canDragSTAdmin, canDragAnyUser],
  );

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    setSPName(sp.spname);
  }, [sp.spname]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateSPName();
  };

  const updateSPName = async () => {
    setIsUpdating(true);
    try {
      const res = await samplePoints.updateSamplePoint({
        SPId: sp.spid,
        SPName: SPName,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        await reFetch();
      } else {
        setError('Error while updating the SP details');
      }
    } catch (e) {
      setError('Error while updating the SP details');
    } finally {
      setIsUpdating(false);
    }
  };

  const resetSPCoordinates = async () => {
    setIsUpdating(true);
    try {
      const res = await samplePoints.updateSamplePoint({
        SPId: sp.spid,
        SPCoordX: -1,
        SPCoordY: -1,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        reFetch();
      } else {
        setError('Error while updating the SP details');
      }
    } catch (e) {
      setError('Error while updating the SP details');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderLoadingIcon = () => {
    if (isUpdating) return <CircularProgress size="30px" />;
    if (isSuccess) return <DoneIcon color="success" />;
  };

  const renderDraggableMarker = () => {
    const x = Number.parseInt(sp.spcoordx);
    const y = Number.parseInt(sp.spcoordy);
    if (x >= 0 && y >= 0) {
      return (
        <Button onClick={resetSPCoordinates} disabled={!canDragAnyUser}>
          Reset
        </Button>
      );
    } else {
      return (
        <div
          ref={drag}
          className="object drop-item"
          style={{ width: `45px`, height: `45px`, position: 'relative' }}
        >
          <svg height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
            <circle
              cx="50%"
              cy="50%"
              r="40%"
              stroke="blue"
              strokeWidth="10%"
              fill="linen"
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize="8px"
            >
              {sp.spnumber}
            </text>
          </svg>
        </div>
      );
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <form onSubmit={handleFormSubmit}>
        <TextField
          value={SPName ?? ''}
          variant="standard"
          onChange={(e) => {
            setSPName(e.target.value);
          }}
          size="small"
          onBlur={updateSPName}
          placeholder={sp.spnumber}
          disabled={!isSafetracesAdmin() && !canDragAnyUser}
        />
      </form>

      <Box width="30px">{renderLoadingIcon()}</Box>
      {renderDraggableMarker()}
    </Box>
  );
};

export default SPItem;
