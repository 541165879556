import { PROJECT_STATUSES } from 'Constants';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';

import { useFloorplanContext } from '../context';

import { isSafetracesAdmin } from 'Config/roles';
import { isViewer } from 'Config/roles';

const PlaceableOPItem = ({ sp, markerSize }) => {
  const itemRef = useRef(null);
  const {
    testData,
    projectData,
    location,
    overrideColorPointId,
    setFocusOnTextField,
  } = useFloorplanContext();
  const canDragSTAdmin =
    (!!projectData?.status &&
      projectData?.status !== PROJECT_STATUSES.PUBLISHED) ||
    location === 'heatmapPage';
  const canDragAnyUser =
    !isViewer() &&
    typeof testData?.testexecuted === 'boolean' &&
    !testData.testexecuted;

  const [, drag] = useDrag({
    type: 'SP',
    item: {
      ...sp,
      type: 'SP',
      id: sp.spid,
    },
    canDrag: isSafetracesAdmin() ? canDragSTAdmin : canDragAnyUser,
  });

  useEffect(() => {
    if (location === 'heatmapPage') {
      addOnCLickListeners();
    }
  }, []);

  // Adjust icon and font size
  const width = 100 * (markerSize / 7.5) ?? 50;
  const fontSize = 8 * (markerSize / 3) ?? 16;

  // Place the icon in the middle
  const left = sp.spcoordx - width / 2;
  const top = sp.spcoordy - width / 2;

  const addOnCLickListeners = () => {
    const el = document.getElementById(`placeable-sp-item-${sp.spid}`);
    if (!el) return;

    el.addEventListener('mousedown', () => {
      itemRef.current = {
        shouldFocusOnTextField: true,
      };
      setTimeout(() => {
        itemRef.current = null;
      }, 500);
    });
    el.addEventListener('mouseup', () => {
      if (itemRef.current?.shouldFocusOnTextField) {
        setFocusOnTextField(`SP-${sp.spid}`);
      }
    });
  };

  if (sp.spcoordx >= 0 || sp.spcoordy >= 0) {
    return (
      <div
        id={`placeable-sp-item-${sp.spid}`}
        ref={drag}
        className="object"
        style={{
          position: 'absolute',
          left,
          top,
          width: `${width}px`,
          height: `${width}px`,
        }}
      >
        <svg height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
          <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke={
              !overrideColorPointId
                ? 'blue'
                : overrideColorPointId === `SP-${sp.spid}`
                ? 'green'
                : 'grey'
            }
            strokeWidth="10%"
            fill="linen"
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={`${fontSize}px`}
          >
            {sp.spnumber}
          </text>
        </svg>
      </div>
    );
  }
  return null;
};

export default PlaceableOPItem;
